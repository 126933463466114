.navbar {
  float: right;
  margin-top: 30px;
  margin-right: 50px;
}

.navbar a {
  margin-left: 20px;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.1em;
}
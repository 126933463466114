$color-green: white;
$color-green-semi-80: #1B1C1E;
$color-green-semi-50: #1B1C1E;
$color-black-semi-30: #1B1C1E;
$break-sm-max: 767px;
$break-md-min: 768px;

$font-default: 'Rubik-Regular', sans-serif;
$font-bold: 'Rubik-Bold', sans-serif;

*:focus {
  outline: none;
}
.academy {
  background-color: #1B1C1E;
  font-family: $font-default;
  color: $color-green;
  line-height: 1.25;
}
.page {
  /* position: relative;
  background: #333;
  min-height: 100vh; */
}
.pageFg {
  position: relative;
  padding: 2rem;
}
.isLoading .pageFg {
  opacity: 0;
}
.mainBg {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#pattern_jyUjl line {
  stroke: 'red';
}
.mainHeader {
  background-color: $color-green-semi-80;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1;
  padding: 0.5rem 1rem;
  font-family: $font-bold;
  @media (max-width: $break-sm-max) {
    display: block;
    width: 100%;
  }
  @media (min-width: $break-md-min) {
    display: flex;
    .left {
      width: 50%;
    }
    .right {
      width: 50%;
      text-align: right;
    }
  }
}
.gameWrap {
  opacity: 0;
  &.active {
    opacity: 1;
  }
}

.commandButton {
  white-space: pre-line;
  min-width: 138px;
  font-size: 18px;
  height: auto;
  background-color: $color-black-semi-30;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 1rem;
  max-height: calc(100vh - 16rem);
  overflow-y: auto;
  font-family: $font-default;
  margin-top: 5px;
  margin-right: 10px ;
  text-align: center;
}
.terminalAlign {
  display: flex;
  justify-content: space-between;
}

.terminalPictureWrap {
  white-space: pre-line;
  width: 45vw;
  background-color: $color-black-semi-30;
  border: 2px solid #1B1C1E;
  border-radius: 5px;
  padding: 1rem;
  height: calc(100vh - 22rem);
  overflow-y: auto;
  font-family: $font-default;
  margin: 0px;
  text-align: center;
}
.terminalPicture {
  max-height: 50vh;
  padding-top: 10%;
}

.terminalOutput {
  white-space: pre-line;
  width: 45vw;
  background-color: $color-black-semi-30;
  border: 2px solid #1B1C1E;
  border-radius: 5px;
  padding: 1rem;
  height: calc(100vh - 22rem);
  // overflow-y: auto;
  font-family: $font-default;
  margin: 0px;
  overflow-y: scroll;
}

.terminalOutputEof {
  height: 1px;
  width: 1px;
  overflow: hidden;
  opacity: 0;
}
.terminalInputWrap {
  margin-top: 1rem;
}
.terminalOutputWrap {
  padding-top: 1rem;
}
.terminalOutputWrap label,
.terminalInputWrap label {
  font-size: 1rem;
  opacity: 0.7;
  text-transform: uppercase;
}
.terminalInput {
  background-color: $color-black-semi-30;
  padding: 1rem;
  width: calc(100% - 2rem);
  display: block;
  border: none;
  font-family: $font-default;
  border: 2px solid white;
  border-radius: 5px;
  color: $color-green;
  font-size: 1rem;
  line-height: 1.25;
}

.LoadingDisplay {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
}
.cassette-wrap {
  width: 100%;
  max-width: 350px;
  position: relative;
  opacity: 0.7;
}
.cassette {
  width: 100%;
}
.cassette-hub {
  animation: cassette-spin 1s linear infinite;
  position: absolute;
  width: 12%;
  height: auto;
  left: 20.75%;
  top: 31.5%;
}
.cassette-wrap.saving .cassette-hub {
  animation: cassette-spin-rev 1s linear infinite;
}
.cassette-hub-right {
  left: 67.5%;
}
@keyframes cassette-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(180deg);
  }
}
@keyframes cassette-spin-rev {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
}
.selectGame {
  .explainer {
    padding: 1rem;
  }

  .buttons {
    max-width: 1000px;
    margin: 0 auto;
  }
  button {
    -webkit-appearance: none;
    border: none;
    display: block;
    padding: 1rem;
    width: 100%;
    margin: 1rem 0;
    background-color: $color-green-semi-50;
    border-radius: 5px;
    border: 2px solid white;
    color: rgb(255, 255, 255);
    font-family: $font-default;
    font-size: 1rem;
    line-height: 1.2;
    font-family: $font-bold;
    &:hover {
      background-color: #303030;
      cursor: pointer;
    }
    &.selected {
      background-color: #303030;
    }
  }
  .textLeft{
    display: inline;
    float: left;
  }
  .textRight{
    display: inline;
    float: right;
  }
}
.mb-1 {
  margin-bottom: 1rem;
}

.fixed-chat {
	background: rgba(0, 0, 0, 0.3);
	position: fixed;
	bottom: 0;
	left: 0;
  bottom: 0;
	border-radius: 5px;
	width: 500px;
	height: 300px;
  overflow: hidden;
}

.panel-chat {
	height: 300px;
	position: relative;
	text-align: left;
	overflow-y: scroll;
}

.panel-chat .header-chat {
	position: absolute;
	top: 0;
	left: 0;
	padding: 10px;
	right: 0;
}

.panel-chat .header-chat input {
	padding: 5px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  color: var(--color-text);
  font-weight: bold;
}

.panel-chat .body-chat {
	height: 200px;
	margin: 50px 0 50px 0;
	padding: 0 10px;
	overflow-y: scroll;
	-ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
	scrollbar-width: none;  /* Hide scrollbar for Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.panel-chat .body-chat::-webkit-scrollbar {
	display: none;
}

.panel-chat .body-chat .message {
  margin: 0.5em 0 0.5em 0;
  overflow-x: none;
  word-wrap: break-word;
}

.panel-chat .message-chat {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
  background: rgba(0, 0, 0, 0.3);
}

.panel-chat .message-chat input[type="text"] {
	width: 100%;
	padding: 15px;
	border: none;
  background: inherit;
  color: var(--color-text);
  font-size: 1em;
}

.panel-chat .message-chat input[type="text"]:focus {
	outline: none;
}

.panel-chat .message-chat input[type="text"]::placeholder {
	color: white;
}

.panel-chat .message-chat input[type="submit"] {
	position: absolute;
	top: 10px;
	right: 10px;
	border: 1px solid #222;
	background: none;
	padding: 5px 15px;
}

.panel-chat .message-chat input[type="submit"]:hover {
	cursor: pointer;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	--color-text: #ddc4a4;
    --color-bg: #0e0e0e;
    --color-link: #ede3e3;
    --color-link-hover: #d68521;
	--page-padding: 1.5rem 3rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Atkinson Hyperlegible', sans-serif;
  color: var(--color-text);
  overflow: hidden;
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
  cursor: pointer;
}

a:hover {
  color: var(--color-link-hover);
  outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
  /* Provide a fallback style for browsers
  that don't support :focus-visible */
  outline: none;
  background: lightgrey;
}

a:focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
  that do support :focus-visible */
  background: transparent;
}

a:focus-visible {
  /* Draw a very noticeable focus style for
  keyboard-focus on browsers that do support
  :focus-visible */
  outline: 2px solid red;
  background: transparent;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// @keyframes loaderAnim {
// 	to {
// 		opacity: 1;
// 		transform: scale3d(0.5,0.5,1);
// 	}
// }

.Lore {
  background-color: var(--color-bg);
  color: var(--color-text);
  
  a {
    text-decoration: none;
    color: var(--color-link);
    outline: none;
    cursor: pointer;
  }

  a:hover {
    color: var(--color-link-hover);
    outline: none;
  }

  /* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
  a:focus {
    /* Provide a fallback style for browsers
    that don't support :focus-visible */
    outline: none;
    background: lightgrey;
  }

  a:focus:not(:focus-visible) {
    /* Remove the focus indicator on mouse-focus for browsers
    that do support :focus-visible */
    background: transparent;
  }

  a:focus-visible {
    /* Draw a very noticeable focus style for
    keyboard-focus on browsers that do support
    :focus-visible */
    outline: 2px solid red;
    background: transparent;
  }

  .unbutton {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }

  .unbutton:focus {
    outline: none;
  }

  main {
    counter-reset: section;
  }

  .splitting .word {
    white-space: nowrap;
  }

  .frame {
    color: var(--color-title);
    padding: var(--page-padding);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: 'title' 'prev' 'demos' 'sponsor';
    justify-content: start;
    justify-items: end;
    margin-bottom: 3rem;
    grid-gap: 0.5rem;
    align-items: start;
    font-weight: 600;
    font-size: 14px;
  }

  .frame a:not(.frame__title-back),
  .line {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }

  .frame a:not(.frame__title-back)::before,
  .line::before {
    content: '';
    height: 1px;
    width: 100%;
    background: currentColor;
    position: absolute;
    top: 90%;
    transition: transform 0.3s;
    transform-origin: 0% 50%;
  }

  .frame a:not(.frame__title-back):hover::before,
  .line:hover::before {
    transform: scaleX(0);
    transform-origin: 100% 50%;
  }

  .frame__title {
    grid-area: title;
    display: flex;
    align-items: center;
  }

  .frame__title-main {
    font-size: 1rem;
    margin: 0;
    font-weight: normal;
  }

  .frame__title-back {
    position: relative;
    display: flex;
    align-items: flex-end;
  }

  .frame__title-back svg {
    fill: currentColor;
  }

  .frame__prev {
    grid-area: prev;
    align-self: start;
  }

  .frame__demos {
    display: flex;
    gap: 1rem;
  }

  .frame__demos span {
    opacity: 0.7;
  }

  .intro {
    height: 60vh;
    display: grid;
    place-items: center;
    text-align: center;
  }

  .intro__title {
    display: grid;
    place-items: center;
    margin: 0;
    line-height: 0.9;
    font-weight: 300;
  }

  .intro__title-pre {
    font-family: 'Roboto Serif', serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: clamp(2rem,10vw,9rem);
  }

  .intro__title-sub {
    font-size: clamp(1rem,6vw,2.75rem);
      text-align: center;
  }

  .intro__info {
    position: relative;
    max-width: 20ch;
    opacity: 0.6;
    line-height: 1.2;
    margin-bottom: 4rem;
    padding-bottom: 1rem;
    font-weight: 600;
    font-size: 14px;
  }

  .intro__info::after {
    content: '';
    position: absolute;
    left: 50%;
    width: 1px;
    height: 2rem;
    background: #fff;
    top: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: relative;
    padding: var(--page-padding);
  }

  .content__hero{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 40vh;
    padding-top: 25vh;
    position: relative;
    margin-bottom: 10vh;
  }

  .content:last-child {
    padding-bottom: 40vh;
  }

  .content--full {
    padding: 0;
  }

  .content p {
    max-width: 660px;
    margin: 1.5rem auto;
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: 400;
  }

  .content--highlight p {
    font-size: clamp(1rem,3vw,2rem);
  }

  .content__title {
    font-size: 8vw;
    line-height: 0.8;
    counter-increment: section;
    text-align: center;
    display: grid;
    gap: 2rem;
  }
  .content__title_no_gap {
    font-size: 12vw;
    line-height: 0.8;
    counter-increment: section;
    text-align: center;
    display: grid;
    gap: 2rem;
  }

  .content__title--left {
    text-align: left;
  }

  .content__title--right {
    text-align: right;
  }

  .content__title::before {
    font-weight: 400;
    font-family: area-normal, sans-serif;
    display: block;
    font-size: 1rem;
    margin-bottom: 10vh;
    text-align: center;
  }

  .content__title--sides {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 100%;
    text-align: left;
    gap: 2rem;
  }

  .content__title--sides span:last-child {
    justify-self: end;
  }

  .font-13 {
    font-weight: initial;
    font-family: "marlide-display-variable", sans-serif;
    font-variation-settings: "wght" 400;
  }

  .font-height {
    line-height: 1.4;
  }

  .font-height-medium {
    line-height: 1.1;
  }

  .font-medium {
    font-size: 6vw;
  }

  .font-larger {
    font-size: 27vw;
  }

  .font-upper {
    text-transform: uppercase;
  }

  .font-sub {
    font-size: 5vw;
    margin-top: 2rem;
      display: block;
  }

  .splitting .font-sub .word {
    display: grid;
    grid-auto-flow: column;
  }

  .char-wrap {
    display: inline-block;
    position: relative;
    overflow: hidden;
  }

  @media screen and (min-width: 53em) {
    .frame {
      justify-items: start;
      grid-gap: 2rem;
      grid-template-columns: auto auto 1fr auto;
      grid-template-rows: auto;
      grid-template-areas: 'title prev demos sponsor';
    }
    .frame__demos {
      justify-self: end;
    }
  }
}
